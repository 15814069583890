import React from "react";

//component-import
import ToolTip from "../ToolTip/ToolTip";

//styles-import
import "./TextField.scss";

/**
 * The TextFieldInputCustom component extends the basic TextFieldInput functionality by
 * supporting the display of additional hint information based on the input value.
 * Use TextFieldInputCustom when you need to provide contextual hints related to the input,
 * otherwise, use the simpler TextFieldInput component.
 */
const TextFieldInputCustom = ({
  id,
  name,
  onChange,
  onBlur,
  value,
  touched,
  error,
  placeholder,
  label,
  required,
  disabled,
  maxLength,
  isToolTip,
  displayValue,
  extraHint = {},
}) => {
  const selectedHint = value ? extraHint[value] : "";

  return (
    <div className={`ui-form-input-section ui-form-content-input`}>
      <div className="ui-form-element-control">
        <input
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          className={`ui-form-input-box ${
            touched && error ? "input-error" : ""
          }`}
          placeholder={placeholder}
          value={displayValue !== undefined ? displayValue : value}
          disabled={disabled}
          maxLength={maxLength}
        />
        {selectedHint && <p className="extra-hint">{selectedHint || "-"}</p>}
      </div>
      <label className="custom-ui-label" htmlFor={id}>
        {label}{" "}
        <ToolTip
          text={isToolTip}
          requiredField={required}
          isToolTip={isToolTip}
        >
          {isToolTip && (
            <div className="ui-info-icon">
              <img src="/images/info-icon.svg" alt="" />
            </div>
          )}
        </ToolTip>
      </label>
      {touched && error ? (
        <img
          src="/images/invalid-input.svg"
          alt="Invalid Input"
          className="ui-invalid-field-icon"
        />
      ) : null}
    </div>
  );
};

export default TextFieldInputCustom;
