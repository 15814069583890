import React from "react";
import ReactDOM from "react-dom";

// component imports
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

// style imports
import "./IpDetailsDialog.scss";

// Utils import
import copyToClipboardTable from "../../../utilities/copyToClipboardTable";

function IpDetailsDialog(props) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-customer-components")
    );
  };

  const copyAllIps = () => {
    copyToClipboardTable(".configure-ip-data");

    ReactDOM.render(
      <SnackbarMessage
        msgtype="success"
        msg={"IP Address(es) copied to clipboard"}
      />,
      document.getElementById("snackbar")
    );
  };

  return (
    <React.Fragment>
      <Dialog
        className="ip-address-details"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-content-container">
            <div className="success-message">
              <h3>Allowed IP Addresses</h3>
            </div>
            <div>
              <button
                className="copy-ip-details"
                onClick={copyAllIps}
                type="button"
              >
                <img src="/images/copy-icon.svg" alt="copy-icon" />
                Copy All
              </button>
            </div>
            <div className="success-account-details">
              <table className="configure-ip-data">
                <tbody>
                  {props.data.ipAddresses.split(" ").map((ip, index) => (
                    <tr key={index}>
                      <td className="detail-value title-case">
                        {ip?.trim().split(",")[0].trim() || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default IpDetailsDialog;
