import React, { createContext, useState } from "react";

const CallbackActionContext = createContext();

export const CallbackActionProvider = ({ children }) => {
  // type of card state
  const [cardType, setCardType] = useState("allCallback");
  // edit callback data state
  const [tableEditData, setTableEditData] = useState({ item: "", edit: false });

  // jumps to add new callback form card
  const configureNewCallback = () => {
    setCardType("newCallback");
  }

  // jumps to add all callback table card
  const allCallback = () => {
    setCardType("allCallback");
    setTableEditData({ item: "", edit: false });
  };

  return (
    <CallbackActionContext.Provider
      value={{
        cardType,
        tableEditData,
        setTableEditData,
        configureNewCallback,
        allCallback,
      }}
    >
      {children}
    </CallbackActionContext.Provider>
  );
};

export default CallbackActionContext;
