import React, { useContext, useRef, useState } from "react";
import ReactDom from "react-dom";
// component styles
import CloseIcon from "@material-ui/icons/Close";
import FetchVaForm from "../FetchVaForm/FetchVaForm";
import VaDetails from "../VaDetails/VaDetails";
import VaDetailsContext from "../../../contexts/VaDetailsContext";
// styles imports
import "./VaDetailModal.scss";

export const VaDetailModal = ({ setShowModal }) => {
  const [responseState, setResponseState] = useState([]);

  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
      setCardType("vaForm");
    }
  };

  // ^ context
  const { cardType, setCardType } = useContext(VaDetailsContext);

  const VA_CARD = {
    vaForm: <FetchVaForm setResponseState={setResponseState} />,
    vaDetails: (
      <VaDetails responseState={responseState} setShowModal={setShowModal} />
    ),
  };

  // render the modal JSX in the portal div (index.html).
  return ReactDom.createPortal(
    <div className="container" ref={modalRef} onClick={closeModal}>
      <div className="modal">
        {/* close modal icon */}
        <button className="close-modal-btn" onClick={() => setShowModal(false)}>
          <CloseIcon />
        </button>

        {/* modal header */}
        <div className="fetch-va-heading">
          <p>Fetch VA Details</p>
        </div>

        {/* enum for cards */}
        {VA_CARD[cardType]}
      </div>
    </div>,
    document.getElementById("portal")
  );
};
