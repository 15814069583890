import React from "react";
// component imports
import AvailableModulesList from "../AvailableModulesList/AvailableModulesList";
// styles imports
import "./ManageCredits.scss";

const ManageCredits = () => {
  return (
    <React.Fragment>
            <AvailableModulesList />
    </React.Fragment>
  );
};

export default ManageCredits;
