import React from "react";
import ReactDOM from "react-dom";
import SnackbarMessage from "../components/SnackbarMessage/SnackbarMessage";

const copyToClipboard = async (copyText) => {
    try {
        await navigator.clipboard.writeText(copyText);
        ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
            document.getElementById("snackbar")
        );
    } catch (err) { }
};

export default copyToClipboard;
