import React from "react";
import "./Loader.scss";

const Loader = ({ type = "loader" }) => (
  <img
    className={type === "tableLoader" ? "loader-div" : "loader"}
    src="/images/loader.gif"
    alt="loader"
  />
);

export default Loader;
