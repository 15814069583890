import React from "react";
import Loader from "../../Loader/Loader";
import "./TableLoader.scss";

const TableLoader = () => {
  return (
    <div className="table-loader-container">
      <Loader type="tableLoader" />
    </div>
  );
};

export default TableLoader;
