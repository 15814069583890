import React from "react";


// styles import
import './MessageBlock.scss';

const MessageBlock = ({ message }) => {
  return <div className="form-message-block">{message}</div>;
};

export default MessageBlock;
