import React from "react";
//styles-import
import "./ResponseDetails.scss";
//components-import
import CopyChip from "../../../UI/CopyChip/CopyChip";
import CopyButton from "../../../UI/CopyButton/CopyButton";
// utils-import
import getSelectedProperties from "../../../utilities/getSelectedProperties";

const ResponseDetails = ({ data, onClick, errorResponse }) => {
  const imgSrc =
    data?.api_status === "SUCCESS"
      ? "/images/onsuccess-tick.svg"
      : data?.api_status === "FAILURE"
      ? "/images/onfailure-error.svg"
      : "/images/onfailure-error.svg";
  const apiStatus = errorResponse?.isError
    ? errorResponse?.api_status?.toLowerCase()
    : data?.api_status?.toLowerCase();

  const desiredProperties =
    apiStatus?.toUpperCase() === "SUCCESS" || apiStatus === "success"
      ? ["company_id", "decentro_txn_id", "message"]
      : ["decentro_txn_id", "message"];
  const response =
    apiStatus?.toUpperCase() === "SUCCESS" || apiStatus === "success"
      ? data
      : errorResponse;

  const selected = getSelectedProperties(response, desiredProperties) || "-";
  const copyableData = selected;

  return (
    <>
      {errorResponse?.isError ? (
        <div className="response-details-container">
          <h4 className="response-details-heading">
            <img
              src={"/images/onfailure-error.svg"}
              className={`${apiStatus || "failed"}-status-icon`}
              alt="status-icon"
            />
            {apiStatus === "failure"
              ? "Failure"
              : apiStatus === "success"
              ? "Success"
              : apiStatus !== ("success" || "failure")
              ? "Failed"
              : null}{" "}
            Response Details
            <div className="right-icons-container">
              {apiStatus === "failure" ? (
                <CopyChip
                  label="Copy All"
                  color="primary"
                  variant="outlined"
                  textToCopy={copyableData !== null ? copyableData : "-"}
                  iconSrc="/images/copy-icon.svg"
                />
              ) : null}
              <img
                src="/images/close.svg"
                className="close-response-details"
                alt="close"
                onClick={onClick}
              />
            </div>
          </h4>
          <div className="response-details-divider"></div>
          <div className="response-details">
            <ul className="response-list">
              {errorResponse?.decentro_txn_id && (
                <li className="response-list-item">
                  <span className="field">Decentro Transaction ID: </span>
                  <span className="value">
                    {errorResponse?.decentro_txn_id || "-"}
                  </span>
                  {!!!apiStatus ? (
                    <CopyButton text={errorResponse?.decentro_txn_id} />
                  ) : null}
                </li>
              )}
              {errorResponse?.message && (
                <li className="response-list-item">
                  <span className="field">Message: </span>
                  <span className="value">{errorResponse?.message || "-"}</span>
                </li>
              )}
              {errorResponse?.error?.message && (
                <li className="response-list-item">
                  <span className="field">Message: </span>
                  <span className="value">
                    {errorResponse?.error?.message || "-"}
                  </span>
                </li>
              )}
              {errorResponse?.api_status && (
                <li className="response-list-item">
                  <span className="field">API status: </span>
                  <span className="value">
                    {errorResponse?.api_status || "-"}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <div className="response-details-container">
          <h4 className="response-details-heading">
            <img
              src={imgSrc}
              className={`${apiStatus}-status-icon`}
              alt="status-icon"
            />
            {data?.api_status === "failure" ? "Failure" : "Success"} Response
            Details
            <div className="right-icons-container">
              <img
                src="/images/close.svg"
                className="close-response-details"
                alt="close"
                onClick={onClick}
              />
            </div>
          </h4>
          <div className="response-details-divider"></div>
          <div className="response-details">
            <div className="right-icons-sub-container">
              <CopyChip
                label="Copy All"
                color="primary"
                variant="outlined"
                textToCopy={copyableData !== null ? copyableData : "-"}
                iconSrc="/images/copy-icon.svg"
              />
            </div>
            {data?.api_status === "SUCCESS" ? (
              <ul className="response-list">
                {data?.data?.company_id && (
                  <li className="response-list-item">
                    <span className="field">Company ID: </span>
                    <span className="value">
                      {data?.data?.company_id || "-"}
                    </span>
                  </li>
                )}
                {data?.decentro_txn_id && (
                  <li className="response-list-item">
                    <span className="field">Decentro Transaction ID:</span>
                    <span className="value">{data.decentro_txn_id || "-"}</span>
                  </li>
                )}
                {data?.message && (
                  <li className="response-list-item">
                    <span className="field">Message: </span>
                    <span className="value">{data.message || "-"}</span>
                  </li>
                )}
                {data?.api_status && (
                  <li className="response-list-item">
                    <span className="field">API Status: </span>
                    <span className="value">{data?.api_status || "-"}</span>
                  </li>
                )}

                {data?.data?.consumer.map((item, index) => (
                  <div className="response-details-container">
                    <div className="right-icons-sub-container">
                      <CopyChip
                        label="Copy All"
                        color="primary"
                        variant="outlined"
                        textToCopy={item}
                        iconSrc="/images/copy-icon.svg"
                      />
                    </div>
                    {/* consumer_urn */}
                    {item?.consumer_urn ? (
                      <li className="response-list-item">
                        <span className="field">Consumer URN: </span>
                        <span className="value">
                          {item?.consumer_urn || "-"}
                        </span>
                      </li>
                    ) : null}
                    {/* provider_code */}
                    {item?.provider_code ? (
                      <li className="response-list-item">
                        <span className="field">Provider Code: </span>
                        <span className="value">
                          {item?.provider_code || "-"}
                        </span>
                      </li>
                    ) : null}
                    {/* refund_min_balance */}
                    {item?.refund_min_balance ? (
                      <li className="response-list-item">
                        <span className="field">Refund Min Balance: </span>
                        <span className="value">
                          {item?.refund_min_balance || "-"}
                        </span>
                      </li>
                    ) : null}
                    {/* chargeback_min_balance */}
                    {item?.chargeback_min_balance ? (
                      <li className="response-list-item">
                        <span className="field">Chargeback Min Balance: </span>
                        <span className="value">
                          {item?.chargeback_min_balance || "-"}
                        </span>
                      </li>
                    ) : null}
                    {/* float_min_balance */}
                    {item?.float_min_balance ? (
                      <li className="response-list-item">
                        <span className="field">Float Min Balance: </span>
                        <span className="value">
                          {item?.float_min_balance || "-"}
                        </span>
                      </li>
                    ) : null}
                  </div>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
export default ResponseDetails;
