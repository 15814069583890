import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import VaDetailsContext from "../../../contexts/VaDetailsContext";
// import copyToClipboard from "../../../utilities/copyToClipboard";
import Loader from "../../Loader/Loader";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
// styles import
import "./VaDetails.scss";

const VaDetails = ({ responseState, setShowModal }) => {
  // ^ context
  const { setCardType } = useContext(VaDetailsContext);

  // & Slider Va Details state
  const [current, setCurrent] = useState(0);
  const length = responseState.length;

  const [disableLeftArrows, setDisableLeftArrows] = useState(true);
  const [disableRightArrows, setDisableRightArrows] = useState(false);

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const closeModalDetails = () => {
    setShowModal(false);
    setCardType("vaForm");
  };

  function copyClip() {
    const elTable = document.querySelector(".va-fetch-data");

    let range, sel;

    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
          document.getElementById("snackbar")
        );
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();
  }

  useEffect(() => {
    if (current === 0) {
      setDisableLeftArrows(true);
      setDisableRightArrows(false);
    }
    if (current === length - 1) {
      setDisableRightArrows(true);
      setDisableLeftArrows(false);
    }
    if (current > 0 && current < length - 1) {
      setDisableLeftArrows(false);
      setDisableRightArrows(false);
    }
  }, [current, length]);

  return (
    <>
      {/* Slider Va Details */}
      {responseState.length !== 0 ? (
        <div className="card-detail-slider">
          {responseState.map((slide, index) => {
            return (
              <div key={index}>
                {index === current && (
                  <div className="success-card-details">
                    <div class="company-name-heading">
                      <p>{slide?.company_name || "NA"}</p>
                    </div>
                    <button
                      className="copy-va-details"
                      // onClick={() => copyToClipboard(JSON.stringify(slide))}
                      onClick={copyClip}
                      type="button"
                    >
                      <img src="/images/copy-icon.svg" alt="copy-icon" />
                      Copy
                    </button>
                    <table className="configure-account-data va-fetch-data">
                      <tbody>
                        <tr>
                          <td className="detail-heading">Company ID</td>
                          <td className="detail-value title-case">
                            {slide?.company_id || "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-heading">Company Name</td>
                          <td className="detail-value title-case">
                            {slide?.company_name || "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-heading">Customer ID</td>
                          <td className="detail-value">
                            {slide?.customer_id || "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-heading">Email Address</td>
                          <td className="detail-value title-case">
                            {slide?.email_address || "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-heading">Mobile Number</td>
                          <td className="detail-value">
                            {slide?.mobile_number || "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-heading">Provider Name</td>
                          <td className="detail-value">
                            {slide?.provider_name || "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-heading">Account Name</td>
                          <td className="detail-value">
                            {slide?.account_name || "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-heading">IFSC Code</td>
                          <td className="detail-value">
                            {slide?.ifsc_code || "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td className="detail-heading">VA Number</td>
                          <td className="detail-value">
                            {slide?.va_number || "NA"}
                          </td>
                        </tr>

                        <tr>
                          <td className="detail-heading">Current Balance</td>
                          <td className="detail-value">
                            {slide?.current_balance || "NA"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            );
          })}

          {responseState.length > 1 && (
            <div className="slider-btn-container">
              <button
                disabled={disableLeftArrows}
                className="left-arrow-btn"
                onClick={prevSlide}
              >
                <img src="/images/left-arrow.svg" alt="left-arrow" />
              </button>
              <p className="slider-count">{current + 1}</p>
              <button
                disabled={disableRightArrows}
                className="right-arrow-btn"
                onClick={nextSlide}
              >
                <img src="/images/right-arrow.svg" alt="left-arrow" />
              </button>
            </div>
          )}

          <div className="done-button-wrapper">
            <button
              className={`done-va-details`}
              onClick={closeModalDetails}
              type="button"
            >
              Done
            </button>
          </div>
        </div>
      ) : (
        <VaDetailsLoader />
      )}
    </>
  );
};

export default VaDetails;

const VaDetailsLoader = () => {
  return (
    <div class="loader-container">
      <Loader type="tableLoader" />
    </div>
  );
};
