import React, { useContext, useState } from "react";
// UI components
import VPA from "./VPA";
import DMO from "./DMO";
// Context imports
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";

const DMOVPALayout = () => {
  // ^ Destructure necessary context values
  const { toSummary, toPaymentChannel } = useContext(OnBoardCompanyPAContext);

  // Manage the enable/disable state of the Next button
  const [isVPANextEnabled, setIsVPANextEnabled] = useState(false);
  // const [isDMONextEnabled, setIsDMONextEnabled] = useState(false);
  const [isDMONextEnabled, setIsDMONextEnabled] = useState({
    enabled: false,
    isDMOSuccess: false,
  });

  // Button is enabled if either VPA or DMO can proceed to the summary
  const isNextButtonEnabled = isVPANextEnabled || isDMONextEnabled;

  // Handle navigation based on DMO success
  const handleNavigation = () => {
    if (isDMONextEnabled.isDMOSuccess) {
      toPaymentChannel();
    } else {
      toSummary();
    }
  };

  return (
    <div id="dmo">
      {/* VPA Section */}
      <VPA setEnableVPANextToSummaryBtn={setIsVPANextEnabled} />

      {/* DMO Section */}
      <DMO setEnableDMONextToSummaryBtn={setIsDMONextEnabled} />

      {/* Next Button */}
      <div className="admin-done-button-container">
        <button
          className={`formik-btn-submit ${
            isNextButtonEnabled ? "active" : "disabled"
          }`}
          type="button"
          // onClick={toSummary}
          // onClick={toPaymentChannel}
          onClick={handleNavigation}
          disabled={!isNextButtonEnabled}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default DMOVPALayout;
