import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import modules from "../../config/Setup/production-modules.json";
import { modulesContext } from "../../contexts/modulesContext";
import "./ProductionTabs.scss";

const ProductionTabs = () => {
  const location = useLocation();
  const [latestPath, setLatestPath] = useState("");
  const [modulesData, setModulesData] = useState(modules.modules);
  const [tabActive, setTabActive] = useState("0");
  const { currentModuleData, setCurrentModuleData } =
    useContext(modulesContext);
  const toggleTab = (index) => {
    setTabActive(index);
    localStorage.setItem("currentActiveTabIndex", index);
  };

  useEffect(() => {
    setLatestPath(location.pathname);
    if (localStorage.getItem("currentActiveTabIndex") === null) {
      localStorage.setItem("currentActiveTabIndex", "0");
      localStorage.setItem(
        "currentSubmenu",
        JSON.stringify({
          module: "manage-companies",
          display_name: "Manage Companies",
          path: "/dashboard/manage-companies/onboard-company",
          submenus: [
            {
              module: "onboard-company",
              display_name: "Onboard Company",
              path: "/dashboard/manage-companies/onboard-company",
              submenus: [],
              icon_path: "/images/onboard-company-icon.svg",
            },
            {
              module: "all-consumers",
              display_name: "All Consumers",
              path: "/dashboard/manage-companies/all-consumers",
              submenus: [],
              icon_path: "/images/all-consumers-icon.svg",
            },
            {
              module: "callbacks",
              display_name: "Callbacks",
              path: "/dashboard/manage-companies/callbacks",
              submenus: [],
              icon_path: "/images/callback-configuration-icon.svg",
            },
            {
              module: "credit-management",
              display_name: "Credit Management",
              path: "/dashboard/manage-companies/credit-management",
              submenus: [],
              icon_path: "/images/credits-management-icon.svg",
            },
            {
              module: "dashboard-users",
              display_name: "Dashboard Users",
              path: "/dashboard/manage-companies/dashboard-users",
              submenus: [],
              icon_path: "/images/user-management-icon.svg",
            },
            {
              module: "settlement-account",
              display_name: "Settlement Account",
              path: "/dashboard/manage-companies/settlement-account",
              submenus: [],
              icon_path: "/images/settlement-active.svg",
            },
            {
              module: "send-credentials",
              display_name: "Send Credentials",
              path: "/dashboard/manage-companies/send-credentials",
              submenus: [],
              icon_path: "/images/send-credentials.svg",
            },
            {
              module: "master-account-setup",
              display_name: "Master Account Setup",
              path: "/dashboard/manage-companies/master-account-setup",
              submenus: [],
              icon_path: "/images/master-account-setup.svg",
            },
            {
              module: "manage-ips",
              display_name: "Manage IPs",
              path: "/dashboard/manage-companies/manage-ips",
              submenus: [],
              icon_path: "/images/ipwhitelist-active.svg",
            },
            {
              module: "settlement-history",
              display_name: "Settlement History",
              path: "/dashboard/manage-companies/settlement-history",
              submenus: [],
              icon_path: "/images/settlement-history.svg",
            },
            {
              module: "minimum-balance",
              display_name: "LA Minimum Balance",
              path: "/dashboard/manage-companies/minimum-balance",
              submenus: [],
              icon_path: "/images/minimum_balance.svg",
            },
            {
              module: "parent-child-mapping",
              display_name: "Parent Child Mapping",
              path: "/dashboard/manage-companies/parent-child-mapping",
              submenus: [],
              icon_path: "/images/parent-child.svg",
            },
          ],
        })
      );
    } else {
      toggleTab(localStorage.getItem("currentActiveTabIndex"));
      updateSubmenus(JSON.parse(localStorage.getItem("currentSubmenu")));
    }
  }, []);

  useEffect(() => {
    setLatestPath(location.pathname);
  }, [tabActive]);

  const updateSubmenus = (item) => {
    setCurrentModuleData(item);
    localStorage.setItem("currentSubmenu", JSON.stringify(item));
  };

  return (
    <div className="menu-tabs">
      {modulesData.map((item, index) => {
        return (
          <div
            key={index}
            className={tabActive == index ? "tab active" : "tab"}
          >
            <NavLink
              className={tabActive == index ? "tab-link active" : "tab-link"}
              to={item.path}
              data-path={item.path}
              data-reference={item.module}
              data-item={JSON.stringify(item)}
              onClick={() => {
                toggleTab(index);
                updateSubmenus(item);
              }}
            >
              {item.display_name}
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default ProductionTabs;
