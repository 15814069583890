import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as Yup from "yup";

//API imports
import axios from "axios";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { APIConfig } from "../../../services/apiConfiguration";

//component-import
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import FormTitle from "../../../UI/FormTitle/FormTitle";
import OnSuccessResponseIconView from "../../../UI/OnSuccessResponseIconView/OnSuccessResponseIconView";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

// context imports
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";
// styles import
import "./CompanyChannelPA.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// Dropdown options
const channelOptions = [
  { label: "Payment Link", value: "PAYMENT_LINK" },
  { label: "Dynamic QR", value: "QR" },
  { label: "Validate VPA", value: "VALIDATE_VPA" },
  { label: "eNACH", value: "ENACH" },
];

const templateOptions = [
  {
    label: "Default Payment QR Template",
    value: "DEFAULT_PAYMENT_QR_TEMPLATE",
  },
  {
    label: "Default Payment Link Template",
    value: "DEFAULT_PAYMENT_LINK_TEMPLATE",
  },
  {
    label: "Default Validate VPA Template",
    value: "DEFAULT_VALIDATE_VPA_TEMPLATE",
  },
  { label: "Default eNACH Template", value: "DEFAULT_ENACH_TEMPLATE" },
];

// Validation schema
const validationSchema = Yup.object().shape({
  channels: Yup.array().of(
    Yup.object().shape({
      channel: Yup.string().required("Please select a channel"),
      template_urn: Yup.string().required("Please select a template"),
    })
  ),
});

const CompanyChannelPA = () => {
  const { companyID, toSettlementAccount } = useContext(
    OnBoardCompanyPAContext
  );
  const [loadingStates, setLoadingStates] = useState([]);
  const [submittedStates, setSubmittedStates] = useState([]);

  // Check if at least one row is submitted to enable the "Add Settlement Account" button
  const isAnyRowSubmitted = submittedStates.some((state) => state);

  const formik = useFormik({
    initialValues: {
      company_id: companyID || "",
      channels: [
        { channel: "", template_urn: "" },
        { channel: "", template_urn: "" },
      ],
    },
    validationSchema,
  });

  const handleRowSubmit = async (index) => {
    const row = formik.values.channels[index];
    if (row.channel && row.template_urn) {
      // Set loading state to true for the current row
      setLoadingStates((prev) => {
        const newStates = [...prev];
        newStates[index] = true;
        return newStates;
      });

      const output = {
        company_id: formik.values.company_id,
        channel: row.channel,
        template_urn: row.template_urn,
      };

      try {
        // API Call for each row
        const response = await APIConfig.API_Client.post(
          paAPIEndpoints.COMPANY_CHANNEL_SETUP.baseUrl +
            paAPIEndpoints.COMPANY_CHANNEL_SETUP.endpoint,
          output,
          { cancelToken: source.token }
        );

        // Handle successful response (status: "SUCCESS")
        if (response.data.status === "SUCCESS") {
          ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={response.data.message} />,
            document.getElementById("snackbar")
          );

          // Set submitted state to true for the current row
          setSubmittedStates((prev) => {
            const newStates = [...prev];
            newStates[index] = true;
            return newStates;
          });
        } else {
          // Handle failure response (status: "FAILURE")
          throw new Error(response?.data?.message || "Submission failed");
        }
      } catch (error) {
        // Handle API failure or error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );

        // Keep the buttons enabled for retry after failure
        setLoadingStates((prev) => {
          const newStates = [...prev];
          newStates[index] = false;
          return newStates;
        });
      }
    }
  };

  return (
    <FormikProvider value={formik}>
      <form>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <FormTitle titleText="Company Channel Mapping" />
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="company_id"
                name="company_id"
                value={formik.values.company_id}
                placeholder={companyID}
                label="Company ID"
                required={true}
                disabled={true}
              />
            </div>

            <FieldArray name="channels">
              {({ remove, push }) => (
                <>
                  {formik.values.channels.map((channel, index) => (
                    <div key={index}>
                      <div className="ui-form-inputs-section">
                        <TextFieldSelect
                          id={`channels.${index}.channel`}
                          name={`channels.${index}.channel`}
                          options={channelOptions}
                          placeholder="Select Channel"
                          label="Channel"
                          value={
                            channelOptions.find(
                              (option) => option.value === channel.channel
                            ) || null
                          }
                          onChange={(option) => {
                            formik.setFieldValue(
                              `channels.${index}.channel`,
                              option ? option.value : ""
                            );
                          }}
                          onBlur={formik.handleBlur}
                          required
                          isDisabled={submittedStates[index]}
                        />
                        {formik.errors.channels?.[index]?.channel &&
                          formik.touched.channels?.[index]?.channel && (
                            <div>{formik.errors.channels[index].channel}</div>
                          )}
                        <TextFieldSelect
                          id={`channels.${index}.template_urn`}
                          name={`channels.${index}.template_urn`}
                          options={templateOptions}
                          placeholder="Select Template"
                          label="Template"
                          value={
                            templateOptions.find(
                              (option) => option.value === channel.template_urn
                            ) || null
                          }
                          onChange={(option) => {
                            formik.setFieldValue(
                              `channels.${index}.template_urn`,
                              option ? option.value : ""
                            );
                          }}
                          onBlur={formik.handleBlur}
                          required
                          isDisabled={submittedStates[index]}
                        />
                        {formik.errors.channels?.[index]?.template_urn &&
                          formik.touched.channels?.[index]?.template_urn && (
                            <div>
                              {formik.errors.channels[index].template_urn}
                            </div>
                          )}
                      </div>
                      <div className="ui-channel-button-container">
                        {formik.values.channels.length > 1 &&
                          !submittedStates[index] && (
                            <button
                              className="secondary-btn"
                              type="button"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </button>
                          )}
                        {submittedStates[index] ? (
                          <OnSuccessResponseIconView />
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleRowSubmit(index)}
                            disabled={
                              !channel.channel ||
                              !channel.template_urn ||
                              loadingStates[index]
                            }
                            className={
                              channel.channel && channel.template_urn
                                ? "active"
                                : ""
                            }
                          >
                            {loadingStates[index] ? "Loading..." : "Submit"}
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="ui-channel-button-container add-more">
                    {/* Disable Add More button if the number of rows is 4 */}
                    {formik.values.channels.length < 4 && (
                      <button
                        className="secondary-btn active"
                        type="button"
                        onClick={() => push({ channel: "", template_urn: "" })}
                      >
                        <span>+</span>Add More
                      </button>
                    )}
                  </div>
                </>
              )}
            </FieldArray>
          </div>
          <div className="ui-channel-button-container">
            <button
              className={isAnyRowSubmitted ? "active" : "disabled"}
              type="button"
              onClick={toSettlementAccount}
              disabled={!isAnyRowSubmitted}
            >
              Add Settlement Account
            </button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default CompanyChannelPA;
