import React, { useContext } from "react";
import { useRef, useState } from "react";
import axios from "axios";
import { APIConfig } from "../../../services/apiConfigurationWithCode";
import { REGEXP } from "../../../utilities/validators/inputValidators";
import VaDetailsContext from "../../../contexts/VaDetailsContext";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";

// styles import
import "./FetchVaForm.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const FetchVaForm = ({ setResponseState }) => {
  // input fields state
  const [vaNumber, setVaNumber] = useState("");
  const [authCode, setAuthCode] = useState("");

  // btn loading state
  const [btnLoading, setBtnLoading] = useState(false);

  // error response state
  const [vaResponse, setVaResponse] = useState("");

  // button validation
  const [isDisabled, setIsDisabled] = useState(true);

  // validations
  const vaNumberValidation = useRef(false);
  const authCodeValidation = useRef(false);

  // ^ context
  const { setCardType, setVaFetchDetails } = useContext(VaDetailsContext);

  // va handler
  const vaNumberHandler = (e) => {
    setVaNumber(e.target.value);

    if (REGEXP.vaRegex.test(e.target.value)) {
      vaNumberValidation.current = false;
      setIsDisabled(false);
    } else {
      vaNumberValidation.current = true;
      setIsDisabled(true);
    }
  };

  // auth handler
  const authCodeHandler = (e) => {
    setAuthCode(e.target.value);

    if (REGEXP.authCodeRegex.test(e.target.value)) {
      authCodeValidation.current = false;
      setIsDisabled(false);
    } else {
      authCodeValidation.current = true;
      setIsDisabled(true);
    }
  };

  // submit handler
  const submitHandler = () => {
    setBtnLoading(true);

    let payloadData = {
      va_number: vaNumber,
    };

    APIConfig.API_Client.defaults.headers.post["authentication_token"] =
      authCode;

    APIConfig.API_Client.post(
      apiEndpointList.FETCH_VA.baseUrl + apiEndpointList.FETCH_VA.endpoint,
      // "https://in.decentro.tech/frontend_sa/decentro/operations/search/virtual_account",
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        setVaFetchDetails(response.data);
        setResponseState(response.data);

        setCardType("vaDetails");
        setBtnLoading(false);
      })
      .catch((error) => {
        setVaResponse(error.response.data.message);
        setBtnLoading(false);
      });
  };

  return (
    <>
      <p className="fetch-va-subtitle">
        Here are some quick details you need to add
      </p>

      {/* modal fields */}
      <div className="fetch-va-fields">
        {/* header input */}
        <div className="text-input-wrapper">
          <div className="text-input-label">Virtual Account Number</div>
          <div
            className={`fetch-va-input ${
              vaNumberValidation.current === true && "error-border"
            } `}
          >
            <input
              type="text"
              placeholder="Enter VA number"
              value={vaNumber}
              className="fetch-va-input"
              onChange={vaNumberHandler}
            />
          </div>
        </div>

        {/* url input */}
        <div className="text-input-wrapper">
          <div className="text-input-label">Authentication Code</div>
          <div
            className={`fetch-va-input ${
              authCodeValidation.current === true && "error-border"
            } `}
          >
            <input
              type="text"
              placeholder="Enter Google Auth Code"
              value={authCode}
              className="fetch-va-input"
              maxLength={6}
              onChange={authCodeHandler}
            />
          </div>
        </div>
      </div>

      {/* form response */}
      {!!vaResponse && vaResponse.length !== 0 ? (
        <div className="error-response-block">{vaResponse}</div>
      ) : null}

      {/* submit details btn */}
      <div className="submit-button-wrapper">
        <button
          className={`submit-va-details ${
            !isDisabled &&
            vaNumber.length !== 0 &&
            authCode.length !== 0 &&
            "active-submit-btn"
          }`}
          onClick={() => {
            submitHandler();
          }}
          type="button"
          disabled={isDisabled}
        >
          {btnLoading ? "Loading..." : "Submit"}
        </button>
      </div>
    </>
  );
};

export default FetchVaForm;
