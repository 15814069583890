import React, { useEffect, useState } from "react";
//API-imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

// components import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";

function getDateFromTransaction({ days_before_today, hour, minutes, seconds }) {
  const date = new Date();
  date.setDate(date.getDate() - days_before_today);
  date.setHours(hour, minutes, seconds);

  return date;
}

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementView = () => {
  //   & state
  const [settlementUrnOptions, setSettlementUrnOptions] = useState([""]);
  const [settlementViewDetails, setSettlementViewDetail] = useState([]);
  const [settlementList, setSettlementList] = useState([]);
  const [isURNLoading, setIsURNLoading] = useState(true);
  const [settlementCycleUrn, setSettlementCycleUrn] = useState({});

  const getSettlementCycleUrn = () => {
    setIsURNLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_SETTLEMENT_CYCLE.baseUrl +
        apiEndpointList.FETCH_SETTLEMENT_CYCLE.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setSettlementViewDetail(response.data);
        setSettlementList(response.data);

        const settlementCycleNames = new Set();
        const options = response.data
          .filter((item) => {
            if (settlementCycleNames.has(item.name)) {
              return false; 
            }
            settlementCycleNames.add(item.name); 
            return true; 
          })
          .map((item) => ({
            value: item.urn,
            label: item.name,
          }));

        setSettlementUrnOptions(options);
        setIsURNLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setSettlementUrnOptions([]);
        setIsURNLoading(false);
      });
  };

  useEffect(() => {
    getSettlementCycleUrn();
  }, []);

  useEffect(() => {
    if (settlementCycleUrn) {
      const filterDetails = (arr, filterOption) => {
        return arr.filter((item) => item.name === filterOption);
      };

      setSettlementList(
        filterDetails(settlementViewDetails, settlementCycleUrn.label)
      );
    }
  }, [settlementCycleUrn]);

  return (
    <div className="ui-form-details">
      <div className="ui-form-content">
        <div className="ui-form-inputs-section">
          {/* urn options */}
          <TextFieldSelect
            id="settlement_cycle_urn"
            name="settlement_cycle_urn"
            onChange={(selectedOption) => setSettlementCycleUrn(selectedOption)}
            options={settlementUrnOptions}
            noOptionsMessage={() => "No such cycle exists"}
            label="Settlement Cycle"
            required={true}
            isLoading={isURNLoading}
            placeholder="Select settlement cycle"
            isformatOptionLabel={false}
            isClearable={false}
          />
        </div>
      </div>

      {/* Details List */}

      {settlementList.map((details, index) => (
        <div className="response-details-container" key={index}>
          <h4 className="response-details-heading">
          {details?.name || "-"}
          </h4>
          <div className="response-details-divider"></div>
          <div className="response-details">
            <ul className="response-list">
              <li className="response-list-item">
                <span className="field">Settlement Cycle URN: </span>
                <span className="value">
                  {details?.urn || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Provider Code: </span>
                <span className="value">{details?.provider_code || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">
                  Underlying Account Consumer Account ID:{" "}
                </span>
                <span className="value">
                  {details?.underlying_account_consumer_bank_account_id || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Description: </span>
                <span className="value">{details?.description || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">Consumers: </span>
                <span className="value">
                  {details?.consumers?.join(",  ") || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Channels: </span>
                <span className="value">
                  {details?.channels?.join(",  ") || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Transactions Between: </span>
                <span className="value">
                {details?.transactions_between?.from
                  ? new Date(getDateFromTransaction(details.transactions_between.from)).toLocaleString()
                  : "-"}
                  {" - "}
                  {details?.transactions_between?.till
                    ? new Date(getDateFromTransaction(details.transactions_between.till)).toLocaleString()
                    : "-"}
              </span>
            </li>
            <li className="response-list-item">
                <span className="field">Channel Config: </span>
                <span className="value">
                  {details?.channel_config ? (
                    <>
                     {Object.keys(details.channel_config).map((channel) => (
                      <div key={channel}>
                        <strong style={{textDecoration: "underline"}}>{channel}</strong>
                        <ul>
                          {details.channel_config[channel].invoke_settlement_activity !== undefined && (
                            <li>
                              <span className="value">Invoke Settlement Activity: </span>
                              <span className="value">{details.channel_config[channel].invoke_settlement_activity.toString()}</span>
                            </li>
                          )}
                          {details.channel_config[channel].invoke_record_settlement !== undefined && (
                            <li>
                              <span className="value">Invoke Record Settlement: </span>
                              <span className="value">{details.channel_config[channel].invoke_record_settlement.toString()}</span>
                            </li>
                          )}
                          {details.channel_config[channel].settlement_record_action && (
                            <li>
                              <span className="value">Settlement Record Action: </span>
                              <span className="value">{details.channel_config[channel].settlement_record_action}</span>
                            </li>
                          )}
                        </ul>
                      </div>
                    ))}
                    </>
                  )
                   : "-"
                  }
                </span>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettlementView;
