import React, { createContext, useState } from "react";

const VaDetailsContext = createContext();

export const VaDetailsProvider = ({ children }) => {
  const [cardType, setCardType] = useState("vaForm");
  const [vaFetchDetails, setVaFetchDetails] = useState([{}]);

  return (
    <VaDetailsContext.Provider
      value={{
        cardType,
        setCardType,
        vaFetchDetails,
        setVaFetchDetails,
      }}
    >
      {children}
    </VaDetailsContext.Provider>
  );
};

export default VaDetailsContext;
