import React from "react";
import ReactDOM from "react-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

//& Sentry Setup
//* Staging
// process.env.REACT_APP_READ_API_ENDPOINT ===
//   "https://in.staging.decentro.tech" &&
//   Sentry.init({
//     dsn: "https://1eb4d6ba875f4a77aeb044a21242c7ab@staging.sentry.decentro.tech/6",
//     integrations: [new BrowserTracing()],

//     tracesSampleRate: 1.0,
//   });

//* Production
// process.env.REACT_APP_READ_API_ENDPOINT === "https://in.decentro.tech" &&
//   Sentry.init({
//     dsn: "https://e502ee3beea843b69ca2cc11d57dd4fa@sentry.decentro.tech/5",
//     integrations: [new BrowserTracing()],

//     tracesSampleRate: 1.0,
//   });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
