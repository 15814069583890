import React from "react";
import ReactDOM from "react-dom";
//ui elements
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
//styles
import "./UserManagementSuccess.scss";

let successResponseData;

function UserManagementSuccess(props) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-user-components")
    );
  };

  function copyResponseDetail() {
    const elTable = document.querySelector(".configure-account-data");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
          document.getElementById("snackbar")
        );
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }
    sel.removeAllRanges();
  }
  successResponseData = props.data;
  return (
    <React.Fragment>
      <Dialog
        className="user-config-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>User configuration</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" className="img-copy" alt="success-icon" />
              <h3>Account setup successful!</h3>
            </div>
            <div className="success-account-details">
              <table className="configure-account-data">
                <tbody>
                  <tr>
                    <td className="detail-heading">Company ID </td>
                    <td className="detail-value title-case">
                      {successResponseData.company_id}
                    </td>
                  </tr>

                  <tr>
                    <td className="detail-heading">Company Name </td>
                    <td className="detail-value title-case">{props.company}</td>
                  </tr>

                  <tr>
                    <td className="detail-heading">User Name </td>
                    <td className="detail-value">{successResponseData.name}</td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Email </td>
                    <td className="detail-value">
                      {successResponseData.email}
                    </td>
                  </tr>
                  {successResponseData.password && <tr>
                    <td className="detail-heading">Password </td>
                    <td className="detail-value">
                      {successResponseData.password}
                    </td>
                  </tr>}
                  <tr>
                    <td className="detail-heading">Mobile No </td>
                    <td className="detail-value">
                      {successResponseData.mobile}
                    </td>
                  </tr>

                  <tr>
                    <td className="detail-heading">User Type </td>
                    <td className="detail-value">{props.user}</td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Status </td>
                    <td className="detail-value">
                      {successResponseData.set_active ? (
                        <div className="active">Active</div>
                      ) : (
                        <div className="in_active">Inactive</div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
             
            </div>
            <div className="success-btn-container">
            <button
                className="copy-account-details"
                onClick={copyResponseDetail}
                type="button"
              >
                <img src="/images/copy-icon.svg"  alt="copy-icon" />
                Copy
              </button>
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default UserManagementSuccess;
