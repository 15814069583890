import React from "react";

//styles-import
import "./CopyButton.scss";

//component-import
import copyToClipboard from "../../utilities/copyToClipboard";

const CopyButton = ({ text }) => {
  return (
    <>
      <button className="ui-copy-button" onClick={() => copyToClipboard(text)}>
        <img src="/images/copy-icon.svg" alt="copy icon" />
      </button>
    </>
  );
};

export default CopyButton;
