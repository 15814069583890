import React from  "react";
import { Route, Redirect } from  "react-router-dom";
import { isLoggedIn } from 'axios-jwt';

const PrivateRoute: React.FC<{ 
	component: React.FC;
	path: string;
	exact: boolean;
}> = (props) => {

	const condition = isLoggedIn();

	return condition?
		(<Route path={props.path} component={props.component} />)
	:
		(<Redirect to="/login" />);
}

export default PrivateRoute;