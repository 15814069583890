import React, { createContext, useState } from "react";
import ReactDOM from "react-dom";
// api config imports
import { APIConfig } from "../services/apiConfiguration";
import axios from "axios";
import paAPIEndpoints from "../config/modules/customer_management/endpoint";
// component imports
import SnackbarMessage from "../components/SnackbarMessage/SnackbarMessage";
// context imports

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CreditManagementContext = createContext();
export const CreditManagementProvider = ({ children }) => {
  //^ states
  const [selectedCompany, setSelectedCompany] = useState(null);
  console.log(
    "🚀 🚀 🚀 ~ file: CreditManagementContext.js:18 ~ CreditManagementProvider ~ selectedCompany:",
    selectedCompany
  );
  // refresh available subscribed module list
  const [refreshModuleList, setRefreshModuleList] = useState(false);

  // credits put(edit) request : @ edit
  const editCreditsApi = (payload) => {
    APIConfig.API_Client.put(
      paAPIEndpoints.COMPANY_API_METER.baseUrl +
        paAPIEndpoints.COMPANY_API_METER.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setRefreshModuleList(!refreshModuleList);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={"Credits Updated successfully"}
          />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <CreditManagementContext.Provider
      value={{
        selectedCompany,
        setSelectedCompany,
        editCreditsApi,
        refreshModuleList,
        setRefreshModuleList,
      }}
    >
      {children}
    </CreditManagementContext.Provider>
  );
};

export default CreditManagementContext;
