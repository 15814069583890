import React, { useContext, useEffect, useState } from "react";
// api config imports
import axios from "axios";
import { APIConfig } from "../../../../services/apiConfiguration";
import paAPIEndpoints from "../../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
// context imports
import CreditManagementContext from "../../../../contexts/CreditManagementContext";
// component imports
import EditCreditTypeDropdown from "../EditCreditTypeDropdown/EditCreditTypeDropdown";
// styles imports
import "./ModulesList.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const ModulesList = ({
  submitEditCreditDetails,
  subscribedModules,
  setSubscribedModules,
}) => {
  // ^ credits context
  const { selectedCompany, refreshModuleList } = useContext(
    CreditManagementContext
  );

  const [isLoading, setIsLoading] = useState("Loader");
  const handleChange = (e, i) => {
    const { value, name } = e.target;

    const newState = [...subscribedModules];
    newState[i] = {
      ...newState[i],
      max_usable_credits: value,
      module_code: name,
      creditInputError: value.match(/^[1-9][0-9]{0,7}?(\.[0-9]{1,2})?$/)
        ? false
        : true,
    };
    setSubscribedModules(newState);
  };

  function getAvailableCreditsByCompany() {
    setIsLoading("Loader");
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_CREDITS_DETAILS.baseUrl +
        paAPIEndpoints.FETCH_CREDITS_DETAILS.endpoint,
      {
        company_id: selectedCompany,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.length === 0) {
          setIsLoading("noData");
        } else {
          setSubscribedModules(
            response.data.map((module) => {
              return {
                ...module,
                max_usable_credits: "",
                module_code: "",
                creditInputError: false,
                operationFunction: "add",
              };
            })
          );
          setIsLoading("showModules");
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getAvailableCreditsByCompany();
  }, [refreshModuleList]);

  if (isLoading === "Loader") {
    return (
      <div className="credits-loader-container">
        <img src="/images/loader.gif" alt="loader" />
      </div>
    );
  }

  if (isLoading === "noData") {
    return (
      <div className="no-modules-heading">
        <p>No Modules Found</p>
      </div>
    );
  }

  if (isLoading === "showModules") {
    return (
      <div className="edit-card-list-container">
        <h3 className="available-modules-heading"> </h3>
        {subscribedModules.map(
          (
            {
              displayName,
              moduleCode,
              availableCredits,
              max_usable_credits,
              creditInputError,
              module_code,
            },
            index
          ) => {
            return (
              <div key={index} className="edit-credit-card">
                <h4 className="card-module-heading">{displayName}</h4>
                <div className="card-divider"></div>

                <div className="credit-details-container">
                  <div className="current-credits">
                    <p className="credits-label">Available Credits</p>
                    <p className="credits-value">{availableCredits}</p>
                  </div>
                  <div className="edit-credits-container">
                    <EditCreditTypeDropdown
                      moduleDisplayName={displayName}
                      subscribedModules={subscribedModules}
                      setSubscribedModules={setSubscribedModules}
                    />

                    <div className="edit-credits-input">
                      <div
                        className={`edit-input-container ${
                          !!creditInputError && "error-border"
                        }`}
                      >
                        <input
                          type="number"
                          placeholder="Enter credits"
                          name={moduleCode}
                          value={max_usable_credits}
                          className="credits-field-input"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  }
};

export default ModulesList;
