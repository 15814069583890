import React, { useState, useEffect } from "react";
// contexts
import { modulesContext } from "../../contexts/modulesContext";
// comoponents
import Header from "../../components/Header/Header";
import ProductionTabPanel from "../../components/ProductionTabPanel/ProductionTabPanel";
import SubmenuBar from "../../components/SubmenuBar/SubmenuBar";
// utils
import ReactMarkdown from "react-markdown";
import ChangeLog from "./change_log.md";
// styles
import "./Changelog.scss";

const Changelog = () => {
  const [changeLogContents, setChangeLogContents] = useState("");
  const [currentModuleData, setCurrentModuleData] = useState({
    module: "manage-companies",
    display_name: "Manage Companies",
    path: "/dashboard/manage-companies/onboard-company",
    submenus: [
      {
        module: "onboard-company",
        display_name: "Onboard Company",
        path: "/dashboard/manage-companies/onboard-company",
        submenus: [],
        icon_path: "/images/onboard-company-icon.svg",
      },
      {
        module: "all-consumers",
        display_name: "All Consumers",
        path: "/dashboard/manage-companies/all-consumers",
        submenus: [],
        icon_path: "/images/all-consumers-icon.svg",
      },
      {
        module: "callbacks",
        display_name: "Callbacks",
        path: "/dashboard/manage-companies/callbacks",
        submenus: [],
        icon_path: "/images/callback-configuration-icon.svg",
      },
      {
        module: "credit-management",
        display_name: "Credit Management",
        path: "/dashboard/manage-companies/credit-management",
        submenus: [],
        icon_path: "/images/credits-management-icon.svg",
      },
      {
        module: "dashboard-users",
        display_name: "Dashboard Users",
        path: "/dashboard/manage-companies/dashboard-users",
        submenus: [],
        icon_path: "/images/user-management-icon.svg",
      },
      {
        module: "settlement-account",
        display_name: "Settlement Account",
        path: "/dashboard/manage-companies/settlement-account",
        submenus: [],
        icon_path: "/images/settlement-active.svg",
      },
      {
        module: "send-credentials",
        display_name: "Send Credentials",
        path: "/dashboard/manage-companies/send-credentials",
        submenus: [],
        icon_path: "/images/send-credentials.svg",
      },
      {
        module: "master-account-setup",
        display_name: "Master Account Setup",
        path: "/dashboard/manage-companies/master-account-setup",
        submenus: [],
        icon_path: "/images/master-account-setup.svg",
      },
      {
        module: "manage-ips",
        display_name: "Manage IPs",
        path: "/dashboard/manage-companies/manage-ips",
        submenus: [],
        icon_path: "/images/ipwhitelist-active.svg",
      },
      {
        module: "settlement-history",
        display_name: "Settlement History",
        path: "/dashboard/manage-companies/settlement-history",
        submenus: [],
        icon_path: "/images/settlement-history.svg",
      },
      {
        module: "minimum-balance",
        display_name: "LA Minimum Balance",
        path: "/dashboard/manage-companies/minimum-balance",
        submenus: [],
        icon_path: "/images/minimum_balance.svg",
      },
      {
        module: "parent-child-mapping",
        display_name: "Parent Child Mapping",
        path: "/dashboard/manage-companies/parent-child-mapping",
        submenus: [],
        icon_path: "/images/parent-child.svg",
      }
    ],
  });

  useEffect(() => {
    fetch(ChangeLog)
      .then((response) => response.text())
      .then((text) => setChangeLogContents(text));
  }, []);

  return (
    <>
      <div className="dashboard">
        <modulesContext.Provider
          value={{ currentModuleData, setCurrentModuleData }}
        >
          <Header />
          <ProductionTabPanel />
          <React.Fragment>
            <div className="submenu-and-content">
              <SubmenuBar />
              <div className="content">
                <div className="change-log-contents">
                  <ReactMarkdown children={changeLogContents} />
                </div>
              </div>
            </div>
          </React.Fragment>
        </modulesContext.Provider>
      </div>
    </>
  );
};

export default Changelog;
