import React from "react";

//styles-import
import Box from "@material-ui/core/Box";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import "./TabComponentWrapper.scss";

const TabComponentWrapper = ({ heading, tabs }) => {
  const [value, setValue] = React.useState(tabs[0]?.value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div className="tab-components-page">
        <div className="tab-components-content">
          <div className="tab-components">
            <h1 className="heading">{heading}</h1>
          </div>
          <div className="tab-components-forms">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        className={`${tab.className} tab-module`}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </TabList>
                </Box>
                {tabs.map((tab) => (
                  <TabPanel key={tab.value} value={tab.value}>
                    <div id={tab.value}>{tab.component}</div>
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TabComponentWrapper;
