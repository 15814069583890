import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Axios
import axios from "axios";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Components
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import { TotpModal } from "./TotpModal/TotpModal";
// Utils
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { randomUUID } from "../../services/randomUUID";
import { REGEXP } from "../../utilities/validators/inputValidators";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ParentChildMapping = () => {
  const [responseData, setResponseData] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    isError: false,
  });

  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);

  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });

  const [showTotpModal, setShowTotpModal] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitForm = () => {
    const payload = {
      ...formik?.values,
    };
    formik?.setSubmitting(true);

    APIConfig.API_Client.put(
      apiEndpointList.PARENT_CHILD_MAPPING.baseUrl +
      apiEndpointList.PARENT_CHILD_MAPPING.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setErrorResponse({
          isError: false,
        });
        setResponseData(response.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Success"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponse(true);
        formik.resetForm();
      })
      .catch((error) => {
        setErrorResponse((prev) => ({
          ...prev,
          isError: true,
          ...error.response.data,
        }));
        setResponseData("");
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponse(true);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  }


  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };

    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
      paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });

  }

  const initialValues = {
    parent_consumer_urn: "",
    consumer_urn: "",
  };

  const {
    URNRegex
  } = REGEXP;

  const validationSchema = Yup.object({
    parent_consumer_urn: Yup.string()
      .required("URN is required")
      .matches(URNRegex, "Invalid URN"),
    consumer_urn: Yup.string()
      .required("URN is required")
      .matches(URNRegex, "Invalid URN"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler(values, action);
      //! reset previous values
      setResponseData("");
      setErrorResponse({
        isError: false,
      });
      setShowResponse(false);
    },
  });

  const closeResponse = () => {
    setShowResponse(false);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            <div className="ui-form-inputs-section">
              {/* 1 */}
              <TextFieldInput
                id="parent_consumer_urn"
                name="parent_consumer_urn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.parent_consumer_urn}
                touched={formik.touched.parent_consumer_urn}
                error={formik.errors.parent_consumer_urn}
                placeholder="Enter parent consumer URN"
                label="Parent URN"
                required={true}
              />
              {/* 2 */}
              <TextFieldInput
                id="consumer_urn"
                name="consumer_urn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.consumer_urn}
                touched={formik.touched.consumer_urn}
                error={formik.errors.consumer_urn}
                placeholder="Enter child consumer URN"
                label="Child URN"
                required={true}
              />
            </div>
            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : "disabled"
                  }`}
                type="submit"
                disabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>

      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          // submitCallback={editNewUser}
          submitCallback={(formik) => submitForm(formik)}
          formik={formik}
        />
      ) : null}

      {showResponse && (
        <ResponseDetails
          data={responseData}
          onClick={closeResponse}
          errorResponse={errorResponse}
        />
      )}
    </>
  );
};
export default ParentChildMapping;
