import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
// Axios
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Components
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import MessageBlock from "../../UI/MessageBlock/MessageBlock";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import { TotpModal } from "./TotpModal/TotpModal";
// Utils
import { REGEXP } from "../../utilities/validators/inputValidators";
import { scrollIntoView } from "../../utilities/scrollIntoView";
import { randomUUID } from "../../services/randomUUID";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const LAMinimumBalance = () => {
  // company options state
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // provider options state
  const [providerOptions, setProviderOptions] = useState([]);
  const [isProviderOptionsLoading, setProviderOptionsIsLoading] =
    useState(false);

  // consumer urn options state
  const [consumerURNOptions, setConsumerURNOptions] = useState([]);
  const [isConsumerURNLoading, setConsumerURNIsLoading] = useState(false);

  // TOTP Specific
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  // response state
  const [responseDetails, setResponseDetails] = useState({});

  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    isError: false,
  });
  const responseRef = useRef(null);

  const handleCloseResponseBlock = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const initialValues = {
    company_name: "",
    reference_id: "",
    provider_code: "",
    consumer_urn: "",
    refund_min_balance: "",
    chargeback_min_balance: "",
    float_min_balance: "",
  };

  const { referenceIdRegex, amountRegex } = REGEXP;

  const validationSchema = Yup.object().shape({
    company_name: Yup.object().shape({
      value: Yup.string().required("Please select an option"),
    }),
    reference_id: Yup.string()
      .required("Reference ID is required")
      .matches(referenceIdRegex, "Invalid Reference ID"),
    // provider_code: Yup.object().shape({
    //   value: Yup.string(),
    //   label: Yup.string(),
    // }),
    // consumer_urn: Yup.object().shape({
    //   value: Yup.string(),
    //   label: Yup.string(),
    // }),

    // This test ensures at least one of the balance fields is filled
    refund_min_balance: Yup.string()
      .nullable()
      .matches(amountRegex, "Invalid Amount"),

    chargeback_min_balance: Yup.string()
      .nullable()
      .matches(amountRegex, "Invalid Amount"),

    float_min_balance: Yup.string()
      .nullable()
      .matches(amountRegex, "Invalid Amount"),

    // Custom test for ensuring at least one balance field is filled
    balances: Yup.mixed().test(
      "at-least-one",
      "At least one balance is required",
      function () {
        const {
          refund_min_balance,
          chargeback_min_balance,
          float_min_balance,
        } = this.parent;
        return (
          refund_min_balance || chargeback_min_balance || float_min_balance
        );
      }
    ),
  });

  // TOTP
  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      //! reset previous values
      handleCloseResponseBlock();
      setErrorResponse({
        isError: false,
      });
      submitHandler(values, action);
    },
  });

  const configureLAMinBalance = (formik, values) => {
    // reset response block submit
    handleCloseResponseBlock();

    const {
      company_name,
      reference_id,
      provider_code,
      consumer_urn,
      refund_min_balance,
      chargeback_min_balance,
      float_min_balance,
    } = values;

    // Transform string balance values to numbers if present
    const transformToNumber = (value) => (value ? Number(value) : undefined);

    // Creating a dynamic payload with transformed values
    let payload = {
      company_id: company_name.value || "",
      reference_id,
      ...(provider_code?.value && { provider_code: provider_code.value }),
      ...(consumer_urn?.value && { consumer_urn: consumer_urn.value }),
      ...(refund_min_balance && {
        refund_min_balance: transformToNumber(refund_min_balance),
      }),
      ...(chargeback_min_balance && {
        chargeback_min_balance: transformToNumber(chargeback_min_balance),
      }),
      ...(float_min_balance && {
        float_min_balance: transformToNumber(float_min_balance),
      }),
    };

    // Submit the payload
    formik.setSubmitting(true);

    APIConfig.API_Client.put(
      paAPIEndpoints.LA_MIN_BALANCE.baseUrl +
        paAPIEndpoints.LA_MIN_BALANCE.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        // response block setters
        setErrorResponse({
          isError: false,
        });
        setShowResponseDetails(true);
        setResponseDetails(response.data);

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        // reset form
        formik.resetForm();
      })
      .catch((error) => {
        // response block setters
        setErrorResponse((prev) => ({
          ...prev,
          isError: true,
          ...error.response.data,
        }));
        setShowResponseDetails(true);
        setResponseDetails(null);

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={
              error?.response?.data?.message ||
              error?.response?.data?.error?.message ||
              "Something went wrong!"
            }
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
        // Scroll to Response Block
        if (responseRef.current) {
          scrollIntoView(responseRef.current, { behavior: "smooth" });
        }
      });
  };

  // Fetching Companies to show in dropdown
  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      paAPIEndpoints.GET_ALL_COMPANY_DETAIL.baseUrl +
        paAPIEndpoints.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.company_id,
          label: item.common_name,
        }));
        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  // & Provider Options Code options
  const getProviderOptions = (id) => {
    setProviderOptionsIsLoading(true);

    APIConfig.API_Client.post(
      paAPIEndpoints.COMPANY_BASED_PROVIDER_FETCH.baseUrl +
        paAPIEndpoints.COMPANY_BASED_PROVIDER_FETCH.endpoint,
      {
        company_id: +id,
      },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          ...item,
          value: item.bank_code,
          label: item.bank_name,
        }));

        setProviderOptions(options);
        setProviderOptionsIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setProviderOptions([]);
        setProviderOptionsIsLoading(false);
      });
  };

  // & Consumer URN options
  const getConsumerURNOptions = (companyID) => {
    setConsumerURNIsLoading(true);

    APIConfig.API_Client.post(
      paAPIEndpoints.COMPANY_BASED_CONSUMER_OPTIONS.baseUrl +
        paAPIEndpoints.COMPANY_BASED_CONSUMER_OPTIONS.endpoint,
      {
        company_id: +companyID,
        limit: 20,
        offset: 0,
      },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          ...item,
          value: item.consumer_urn,
          label: item.consumer_urn,
        }));

        setConsumerURNOptions(options);
        setConsumerURNIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setProviderOptions([]);
        setConsumerURNIsLoading(false);
      });
  };

  useEffect(() => {
    getCompanyOptions();
  }, []);

  useEffect(() => {
    if (formik?.values?.company_name?.value) {
      // Reset provider_code and consumer_urn fields to null
      formik.setFieldValue("provider_code", null);
      formik.setFieldValue("consumer_urn", null);

      // Fetch new provider and consumer options based on company_id
      getProviderOptions(formik?.values?.company_name?.value);
      getConsumerURNOptions(formik?.values?.company_name?.value);
    }
  }, [formik?.values?.company_name]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_name"
                name="company_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_name", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                required={true}
                isLoading={isLoading}
                placeholder="Select company name"
                isformatOptionLabel={true}
                isClearable={false}
              />

              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik?.errors?.reference_id}
                placeholder="Enter reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
            </div>

            {/* 2 */}
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                onChange={(selectedOption) =>
                  formik.setFieldValue("provider_code", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("provider_code", true)}
                value={formik.values.provider_code}
                options={providerOptions}
                noOptionsMessage={() => "No such provider exists"}
                label="Provider"
                required={false}
                isLoading={isProviderOptionsLoading}
                placeholder="Select provider"
                isformatOptionLabel={true}
              />

              <TextFieldSelect
                id="consumer_urn"
                name="consumer_urn"
                onChange={(selectedOption) =>
                  formik.setFieldValue("consumer_urn", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("consumer_urn", true)}
                value={formik.values.consumer_urn}
                options={consumerURNOptions}
                noOptionsMessage={() => "No such consumer urn exists"}
                label="Consumer URN"
                required={false}
                isLoading={isConsumerURNLoading}
                placeholder="Select consumer urn"
                isformatOptionLabel={false}
              />
            </div>

            {/* Message Block */}
            <MessageBlock message="Any one of the fields below is mandatory" />

            {/* 3 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="refund_min_balance"
                name="refund_min_balance"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.refund_min_balance}
                touched={formik.touched.refund_min_balance}
                error={formik?.errors?.refund_min_balance}
                placeholder="Enter refund minimum balance"
                label="Refund Min Balance"
                required={false}
                disabled={false}
              />

              <TextFieldInput
                id="chargeback_min_balance"
                name="chargeback_min_balance"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.chargeback_min_balance}
                touched={formik.touched.chargeback_min_balance}
                error={formik?.errors?.chargeback_min_balance}
                placeholder="Enter chargeback minimum balance"
                label="Chargeback Min Balance"
                required={false}
                disabled={false}
              />
            </div>

            {/* 4 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="float_min_balance"
                name="float_min_balance"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.float_min_balance}
                touched={formik.touched.float_min_balance}
                error={formik?.errors?.float_min_balance}
                placeholder="Enter float minimum balance"
                label="Float Min Balance"
                required={false}
                disabled={false}
              />
            </div>

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : "disabled"
                }`}
                type="submit"
                disabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>

      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={(formik) =>
            configureLAMinBalance(formik, formik.values)
          }
          formik={formik}
        />
      ) : null}

      {/* Response Block */}
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails
            data={responseDetails}
            onClick={handleCloseResponseBlock}
            errorResponse={errorResponse}
          />
        </div>
      )}
    </>
  );
};

export default LAMinimumBalance;
