import React, { useState, useEffect, useRef } from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";
import { randomUUID } from "../../services/randomUUID";

//API-imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

//component-import
import MultiInputField from "../../UI/MultiInputField/MultiInputField";
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import ResponseDetails from "./ResponseDetails/ResponseDetails";

//styles-import
import "../../UI/TextField/TextField.scss";

//utility-import
import { scrollIntoView } from "../../utilities/scrollIntoView";
import { REGEXP } from "../../utilities/validators/inputValidators";

const initialValues = {
  consumers: [],
  settlement_cycle_urn: "",
  action: "",
};

const validationSchema = Yup.object({
  consumers: Yup.array()
    .of(
      Yup.string()
        .required("Consumer URN is required")
        .matches(REGEXP.URNRegex, "Invalid Consumer URN")
    )
    .min(1, "At least one consumer URN is required")
    .required("At least consumer URN is required"),
  settlement_cycle_urn: Yup.object()
    .shape({
      value: Yup.string().required("Please select an option"),
      label: Yup.string().required("Please select an option"),
    })
    .required("Please select an option"),
  action: Yup.object()
    .shape({
      value: Yup.string().required("Please select an option"),
      label: Yup.string().required("Please select an option"),
    })
    .required("Please select an option"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementConfig = () => {
  const [settlementUrnOptions, setSettlementUrnOptions] = useState([""]);
  const [isURNLoading, setIsURNLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  let actionDropDownValues = [
    {
      value: "ADD",
      label: "ADD",
    },
    {
      value: "REMOVE",
      label: "REMOVE",
    },
  ];

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      let payloadData;

      if (values.action.label === "ADD") {
        payloadData = {
          reference_id: randomUUID(),
          settlement_cycle_urn: values.settlement_cycle_urn.value,
          add_consumers: values.consumers,
        };
      }

      if (values.action.label === "REMOVE") {
        payloadData = {
          reference_id: randomUUID(),
          settlement_cycle_urn: values.settlement_cycle_urn.value,
          remove_consumers: values.consumers,
        };
      }

      action.setSubmitting(true);
      APIConfig.API_Client.put(
        apiEndpointList.UPDATE_SETTLEMENT_CYCLE.baseUrl +
          apiEndpointList.UPDATE_SETTLEMENT_CYCLE.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getSettlementCycleUrn = () => {
    setIsURNLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_SETTLEMENT_CYCLE.baseUrl +
        apiEndpointList.FETCH_SETTLEMENT_CYCLE.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.urn,
          label: item.name,
        }));

        setSettlementUrnOptions(options);
        setIsURNLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setSettlementUrnOptions([]);
        setIsURNLoading(false);
      });
  };

  useEffect(() => {
    getSettlementCycleUrn();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Fill settlement details to continue</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              {/* urn options */}
              <TextFieldSelect
                id="settlement_cycle_urn"
                name="settlement_cycle_urn"
                onChange={(selectedOption) =>
                  formik.setFieldValue("settlement_cycle_urn", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("settlement_cycle_urn", true)
                }
                value={formik.values.settlement_cycle_urn}
                options={settlementUrnOptions}
                noOptionsMessage={() => "No such URN exists"}
                label="Settlement Cycle"
                required={true}
                isLoading={isURNLoading}
                placeholder="Select settlement cycle"
                isformatOptionLabel={true}
                showOnlyLabelWhenSelected={true}
              />

              {/* action field */}
              <TextFieldSelect
                id="action"
                name="action"
                onChange={(selectedOption) =>
                  formik.setFieldValue("action", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("action", true)}
                value={formik.values.action}
                options={actionDropDownValues}
                noOptionsMessage={() => "No such action exists"}
                label="Action"
                required={true}
                placeholder="Select action"
                isformatOptionLabel={false}
                isClearable={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              {/* company options */}
              <MultiInputField
                id="consumers"
                name="consumers"
                value={formik.values.consumers}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                touched={formik.touched.consumers}
                error={formik.errors.consumers}
                placeholder={
                  formik.values.consumers.length > 0 ? "" : "Enter consumer URN"
                }
                label="Consumer(s)"
                required={true}
                customErrorMessage={"Invalid consumer URN"}
                extraHint={"Press Enter after entering each consumer URN"}
              />
            </div>

            <div className="settlement-details-submit ui-button-container">
              <button
                className={`settlement-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default SettlementConfig;
